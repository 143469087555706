import React from "react"

interface Props {
  id: string
}

const Video: React.FC<Props> = ({ id, children }) => (
  <a href={`https://www.youtube.com/watch?v=${id}`} target="_blank">
    {children}
  </a>
)

export default Video
