import React from "react"
import Layout from "../components/layout"
import InfoSection from "../components/InfoSection"
import Video from "../components/Video"

const videos = [
  "41WmS3c3mqA",
  "s35GIXoYwHo",
  "GT4KAJxTHWM",
  "zw_vOS7rVc4",
  "P5vwlNZGsNg",
]

const VideoPage: React.FC = () => (
  <Layout>
    <InfoSection title="Clinic Video">
      <div className="hero">
        <div className="hero-body">
          <div className="columns">
            {videos.map((video, index) => (
              <div className="column video-col" key={video}>
                <Video id={video}>
                  <img src={`/video/0${index + 1}.png`} alt={video} />
                </Video>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
    </InfoSection>
  </Layout>
)

export default VideoPage
